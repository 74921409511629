import React from 'react';

import './Gallery.css';

import { images } from '../../constants';
import { SubHeading } from '../../components'; 
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

//function to loop over gallery imgs
const galleryImages = [
	images.gallery01,
	images.gallery02,
	images.gallery03,
	images.gallery04
];

const Gallery = () => {
	const scrollRef = React.useRef(null);

	const scroll = (direction) => {
		const { current } = scrollRef;

		if (direction === 'left') {
			current.scrollLeft -= 300;
		} else {
			current.scrollLeft += 300;
		}
	}
	
	return (
		<div className='app__gallery flex__center'>
			<div className='app__gallery-content'>
				<SubHeading title='Instagram' />
				<h1 className='headtext__cormorant'>Photo Gallery</h1>
				<p style={{color: '#AAA', marginTop: '2rem'}} className='p__opensans'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisque egestas mu.</p>
				<button type='button' className='custom__button' onClick={() => window.open('https://renatak.github.io/react_gallery_app123/#/react_gallery_app123', '_blank')}>Search our Gallery</button>
			</div>

			<div className='app__gallery-images'>
				<div className='app__gallery-images_container' ref={scrollRef}>
					{galleryImages.map((image, index) => (
						<div className='app__gallery-images_card flex__center' key={`gallery_image-${index + 1}`}>
							<img src={image} alt='gallery' />
								<BsInstagram className='gallery__image-icon' onClick={() => window.open('https://www.instagram.com')} />
						</div>
					))}
				</div>

				<div className='app__gallery-images_arrows' >
					<BsArrowLeftShort className='gallery__arrow-icon' onClick={() => scroll('left')} />
					<BsArrowRightShort className='gallery__arrow-icon' onClick={() => scroll('rights')} />
				</div>

			</div>
		</div>
	)
};

export default Gallery;
